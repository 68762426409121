import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Structures } from '@clavisco/core';
import { IMenuNode } from '@clavisco/menu';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private readonly CONTROLLER = 'api/Menu';
  constructor(private http: HttpClient) { }

  Get(): Observable<Structures.Interfaces.ICLResponse<IMenuNode[]>>
  {
    return this.http.get<Structures.Interfaces.ICLResponse<IMenuNode[]>>(this.CONTROLLER);
  }
}
