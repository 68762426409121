 <mat-form-field appearance="outline" class="search-field">
   <mat-label>Buscar socio de negocio</mat-label>
    <input placeholder="Buscar socio de negocio" type="text" matInput
         [formControl]="businessPartnerFormControl" [matAutocomplete]="auto">
  <mat-icon matPrefix>search</mat-icon>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let bp of businessPartner$ | async" [value]="bp"
              (onSelectionChange)="onSelectOption(bp)">
    {{bp.CardCode}} - {{bp.CardName}}
  </mat-option>
</mat-autocomplete>
 </mat-form-field>

