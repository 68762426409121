import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Structures} from '@clavisco/core';
import {Observable} from 'rxjs';
import {ISettings} from '../interfaces/i-settings';
import {IValidateInventoryTable} from "../interfaces/i-items";
import {IFieldsBusinesPartner, IMargin} from '../interfaces/i-company';
import ICLResponse = Structures.Interfaces.ICLResponse;
import {IShorcuts} from "@app/interfaces/i-shorcuts";

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly CONTROLLER = 'api/Settings';

  constructor(private http: HttpClient) {
  }

  Get<T>(_Code?: string): Observable<Structures.Interfaces.ICLResponse<T>> {
    let path = this.CONTROLLER;

    if (_Code) {
      path += `/?Code=${_Code}`;
    }

    return this.http.get<Structures.Interfaces.ICLResponse<T>>(path)
  }

  Post(_settings: ISettings): Observable<Structures.Interfaces.ICLResponse<ISettings>> {
    return this.http.post<Structures.Interfaces.ICLResponse<ISettings>>(this.CONTROLLER, _settings);
  }

  PostSettings(_settings: ISettings[]): Observable<Structures.Interfaces.ICLResponse<ISettings[]>> {
    let path = `${this.CONTROLLER}/list`;
    return this.http.post<Structures.Interfaces.ICLResponse<ISettings[]>>(path, _settings);
  }

  PatchSettings(_settings: ISettings[]): Observable<Structures.Interfaces.ICLResponse<ISettings[]>> {
    let path = `${this.CONTROLLER}/list`;
    return this.http.patch<Structures.Interfaces.ICLResponse<ISettings[]>>(path, _settings);
  }

  Patch(_settings: ISettings, _Code?: string): Observable<Structures.Interfaces.ICLResponse<ISettings>> {
    let path = `${this.CONTROLLER}?Code=${_Code}`;

    return this.http.patch<Structures.Interfaces.ICLResponse<ISettings>>(path, _settings);
  }

  GetValidateInventoryTables(): Observable<Structures.Interfaces.ICLResponse<IValidateInventoryTable[]>> {
    return this.http.get<Structures.Interfaces.ICLResponse<IValidateInventoryTable[]>>(`${this.CONTROLLER}/ValidateInventoryTables`);
  }

  GetMarginTables(): Observable<Structures.Interfaces.ICLResponse<IMargin[]>> {
    return this.http.get<Structures.Interfaces.ICLResponse<IMargin[]>>(`${this.CONTROLLER}/MarginsTables`);
  }


  GetFieldsBusinessPartner(): Observable<Structures.Interfaces.ICLResponse<IFieldsBusinesPartner[]>> {
    return this.http.get<Structures.Interfaces.ICLResponse<IFieldsBusinesPartner[]>>(`${this.CONTROLLER}/FieldsBusinessPartner`);
  }

  getShorcuts(): Observable<ICLResponse<IShorcuts[]>> {
    return this.http.get<ICLResponse<IShorcuts[]>>(`${this.CONTROLLER}/Shorcuts`);
  }


}
